const logotext = "NOTSODIFFERENT";
const meta = {
    title: "NOTSODIFFERENT",
    description: "NOTSODIFFERENT website",
};

const introdata = {
    title: "WHO WE ARE",
    animated: {
        first: "Videographers",
        second: "Computer Scientists",
        third: "Photographers",
        fourth: "Rappers",
        fifth: "Community Organizers",
        sixth: "Graphic Designers",
        seventh: "Instrumentalists",
        eight: "Fashion Designers",
        ninth: "Filmmakers",
        tenth: "Entrepreneurs",
        eleventh: "Producers",
        twelveth: "Animators",
        thirteenth: "DJs",
    },
    description: "A brief running description of the labels NSD members hold",
};

const dataabout = {
    title: "FOUNDERS",
    aboutme1: "Elliot Urgent",
    aboutme2: "Jordan Walendom",
    aboutme3: "Namoo Song",
};

const worktimeline2 = [
    {
        jobtitle: "Cirque du Salon: A Collaboration with Salon 149",
        where: "Salon149",
        date: "02/03/2023",

    },
    {
        jobtitle: "NSD Super Bowl",
        where: "Waterman Co-op",
        date: "02/10/2023",

    },
]

const worktimeline = [
    {
        jobtitle: "Small Victories Winter Welcome Back DJ Set",
        where: "Fete Music Hall",
        date: "01/27/2023",

    },
    {
        jobtitle: "WBRU Live Sessions Performance",
        where: "88 Benevolent St",
        date: "12/16/2022",

    },
    {
        jobtitle: "NOTSODIFFERENT It's Over Already  Concert",
        where: "195 Brown St",
        date: "12/10/2022",

    },
    {
        jobtitle: "Small Victories Met Gala DJ Set",
        where: "The Met",
        date: "12/09/2022",

    },
    {
        jobtitle: "Tunes for Change Charity Concert DJ Set",
        where: "Fete Music Hall",
        date: "12/08/2022",

    },
    {
        jobtitle: "Brown Concert Agency Boiler Room DJ Set",
        where: "Machines With Magnets",
        date: "11/17/2022",

    },
    {
        jobtitle: "Sounds At Brown Performance",
        where: "Leung Gallery",
        date: "11/12/2022",

    },
    {
        jobtitle: "Spilled Milk Art Installation",
        where: "Wayland Arch",
        date: "11/06/2022",

    },
    {
        jobtitle: "BΩXTOBER Halloween DJ Set",
        where: "Kaspar Multipurpose Room",
        date: "10/29/2022",

    },
    {
        jobtitle: "Fashion At Brown Performance",
        where: "Patriot's Court",
        date: "10/21/2022",

    },
    {
        jobtitle: "BΩX Welcome B(l)ack Afterparty DJ Set",
        where: "Patriot's Court",
        date: "10/21/2022",
    },

];

const mission = {
    title: "WHAT WE ARE",
    motivation: "NOTSODIFFERENT is an artist collective born out of Brown " +
     "and RISD centered around hip hop culture. Established in October of " +
     "2022, NSD aims to connect various student artists and organizers to build a vibrant, collaborative " +
     "community fueled by passion.", 
};

const skills = [
    {
        name: "Javascript",
        value: 80,
    },
    {
        name: "React",
        value: 60,
    },
];

const services = [{
        title: "UI & UX Design",
    },
];

const dataportfolio = [{
        img: "https://picsum.photos400/?grayscale",
        desctiption: "Responsive Design",
        link: "https://NOTSODIFFERENT.github.io/responsivedesign/",
    },
    {
        img: "https://picsum.photos/400800/?grayscale",
        desctiption: "Storyboards",
        link: "https://NOTSODIFFERENT.github.io/storyboards/",
    },
    {
        img: "https://picsum.photos0/?grayscale",
        desctiption: "Development",
        link: "https://NOTSODIFFERENT.github.io/development/",
    },
    {
        img: "https://picsum.photos400/600/?grayscale",
        desctiption: "Bakery",
        link: "https://NOTSODIFFERENT.github.io/react-studio/",
    }
];

const contactConfig = {
    YOUR_NAME: "jordan_walendom@brown.edu",
};

const socialprofils = {
    instagram: "https://www.instagram.com/notsodiffer.ent/?hl=en",
    spotify: "https://open.spotify.com/user/31kos3dkbqopkxdu7sr4ipnhc6nu",
};

const shop = {
    title: "Shop",
    forgive: "PLEASE EXCUSE OUR APPEARANCE WHILE WE’RE UNDER CONSTRUCTION",
    content: "Coming Soon",
}

const archives = {
    title: "Archives",
    forgive: "PLEASE EXCUSE OUR APPEARANCE WHILE WE’RE UNDER CONSTRUCTION",
    content: "Coming Soon",
}

export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    worktimeline2,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
    mission,
    shop,
    archives,
};
